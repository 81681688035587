import React, { PureComponent } from 'react'
import cv from '../assets/CV_Anthony-Luong.pdf'

export class Header extends PureComponent {
    render() {
        return (
            <nav class="text-gray-800 py-8 max-w-7xl hidden lg:block mx-auto text-center pr-8">
                <ul class="flex flex-row justify-end my-auto">
                    <a href="#experiences"><li class="font-bold pr-8 hover:text-gray-700">experiences</li></a>
                    <a href="#projects"><li class="font-bold pr-8 hover:text-gray-700">projects</li></a>
                    <a href="#contact"><li class="font-bold pr-8 hover:text-gray-700">contact</li></a>
                    <a href={cv}><li class="bg-blue-400 hover:bg-blue-500 px-2 rounded-full font-bold cursor-pointer">Mon CV</li></a>
                </ul>
            </nav>
        )
    }
}

export default Header
