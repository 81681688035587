import React from 'react'

function Headline() {
    return (
        <div class="max-w-6xl mx-auto pt-12 lg:pt-14 lg:pl-8 xl:pl-0">
            <div class="text-gray-800 mx-4 lg:mx-0 lg:max-w-4xl text-center lg:text-left">
            <h1 class="text-4xl xl:text-5xl font-bold">Bonjour, je suis Anthony 👋</h1>
            <p class="mt-4 text-lg xl:text-xl">Etudiant en développement informatique en troisième année à Epitech, <br/>
                je suis un passionné de technologie et d’informatique.
            </p>
            </div>
        </div>
    )
}

export default Headline
