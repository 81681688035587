import React from 'react'

function Card({name, image, description, link}) {
    return (
        <div class="hover:bg-gray-100 rounded-lg py-2 transform hover:-translate-y-1 duration-200 hover:scale-105">
            <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={name} class="rounded-lg border-2 border-gray-100 mb-4 lg:max-h-48 lg:max-w-none lg:h-48 lg:block mx-auto"/>
            <h3 class="text-lg font-semibold mb-2">{name}</h3>
            <p class="mx-4">{description}</p>
            </a>
        </div>
    )
}

export default Card
