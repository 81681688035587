import React from 'react'
import Card from './Card'
import letsignit from '../../assets/letsignit.png'
import avatar from '../../assets/avatar.png'

function Experiences() {
    return (
        <div id="experiences" class="w-full bg-blue-300 text-center py-8">
            <h2 class="text-2xl font-bold mb-4">Expériences</h2>
            <p class="mx-2 text-lg">Durant mon cursus à Epitech, j’ai eu la possibilité de travailler dans divers entreprises afin d’acquérir de nouvelles connaissances. </p>
            <div class="grid grid-rows-1 lg:grid-cols-2 gap-8 xl:gap-0 space-x-0 lg:space-x-36 mx-8 lg:mx-20 xl:mx-64 my-8">
                <Card blankState="_blank" name="Letsignit" duration="6 mois" role="J’ai intégré l’équipe QA (Quality Assurance) afin d’y élaborer de tests automatisés d’interface et d’APIs." description="Letsignit est une entreprise qui édite une solution de signatures mails." image={letsignit} link="https://letsignit.fr"/>
                <Card name="Votre entreprise ?" width="200" height="190" duration="8 mois" role="Je suis à la recherche d'une alternance de Janvier 2022 à Août 2022 dans le domaine du développement informatique." image={avatar} link="#contact"/>
            </div>
        </div>
    )
}

export default Experiences