import React from 'react'
import avatar from '../assets/anthony.jpeg'
import react from '../assets/bxl-react.svg'
import html5 from '../assets/bxl-html5.svg'
import css3 from '../assets/bxl-css3.svg'
import tailwind from '../assets/bxl-tailwind-css.svg'
import wordpress from '../assets/bxl-wordpress.svg'
import docker from '../assets/bxl-docker.svg'
import php from '../assets/bxl-php.svg'
import python from '../assets/bxl-python.svg'
import javascript from '../assets/bxl-javascript.svg'
import git from '../assets/bxl-git.svg'
import cplusplus from '../assets/bxl-c-plus-plus.svg'
import c from '../assets/c.svg'

function About() {
    return (
        <div id="about" class="max-w-6xl flex flex-col mx-auto lg:flex-row items-center mb-8 lg:mb-36">
            <div class="lg:w-1/2">
                <img class="block rounded-full lg:border-4 lg:border-blue-300 mx-auto lg:ml-2 mb-4 px-2 lg:px-0 lg:mb-0" src={avatar} width="400" height="400"></img>
            </div>
            <div class="mx-2 lg:mx-0 lg:w-1/2 text-center lg:text-left">
                <p class="text-lg my-2 mr-2">
                <p>Je suis une personne curieuse, toujours à
                l’affût des nouvelles technologies en vogue,
                je suis appliqué et rigoureux dans mon travail.</p>

                <p class="mt-2">Je suis également autonome et j’ai la capacité d’apprendre rapidement de nouvelles technologies.</p>

                <p class="mt-2">Je possède également des notions en UI Design ainsi qu’en assistance clientèle.</p>
                </p>
                
                <div class="flex mx-auto pt-2 text-center justify-center lg:justify-start flex-wrap">
                <img class="w-12 mr-1" src={react} title="reactjs"></img>
                <img class="w-12 mx-1" src={html5} title="html"></img>
                <img class="w-12 mx-1" src={css3} title="css"></img>
                <img class="w-12 mx-1" src={tailwind} title="tailwindcss"></img>
                <img class="w-12 mx-1" src={wordpress} title="wordpress"></img>
                <img class="w-12 mx-1" src={docker} title="docker"></img>
                <img class="w-12 mx-1" src={php} title="php"></img>
                <img class="w-12 mx-1" src={python} title="python"></img>
                <img class="w-12 mx-1" src={javascript} title="javascript"></img>
                <img class="w-12 mr-1" src={git} title="git"></img>
                <img class="w-12 mr-2" src={c} title="c"></img>
                <img class="w-12 mr-1" src={cplusplus} title="c++"></img>
                <i class="fab fa-laravel ml-2 mt-2 fa-2x" title="laravel"></i>
                <i class="fab fa-symfony ml-4 mt-2 fa-2x" title="symfony"></i>
                </div>
            </div>
        </div>
    )
}

export default About
