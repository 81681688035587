import React from 'react'
import Card from './Card'
import discordfrance from "../../assets/discordfrance.png"
import epistreet from "../../assets/epistreet.png"
import stageair from "../../assets/stageair.png"
import rpg from "../../assets/rpg.png"
import trade from "../../assets/trade.jpg"
import arcade from "../../assets/arcade.png"

function Projects() {
    return (
        <div id="projects" class="mt-8 mb-10">
            <h2 class="text-2xl font-bold mb-4 text-center">Projets</h2>
            <p class="mx-2 lg:mx-32 text-lg text-center">Durant ces dernières années à Epitech, j’ai réalisé de nombreux projets mais également des projets personnels afin d’agrandir mes champs de connaissances.</p>

            <div class="grid grid-rows-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 text-center my-8 mx-8">
                <Card name="Discord France" description="Discord France est un site d’actualité et d’entraide pour les utilisateurs francophones de Discord." image={discordfrance} link="https://discord-france.fr"></Card>
                <Card name="EpiStreet" description="EpiStreet est une application mobile qui liste des images streetart. L’application a été créée durant une jam Epitech." image={epistreet} link="https://github.com/Noubouille/EpiStreet"></Card>
                <Card name="StageAir" description="StageAir était un projet Hub Epitech consistant à créer une plateforme de recherche d'emploi pour les étudiants." image={stageair} link="https://stageair.pro"></Card>
                <Card name="Arcade" description="Projet Epitech ayant pour but de créer sa propre “borne d’arcade” afin de pouvoir charger des jeux avec différentes contraintes imposées en C++." image={arcade} link="https://github.com/Noubouille/Arcade"></Card>
                <Card name="myRPG" description="Projet graphique réalisé à Epitech en groupe ayant pour but de créer un jeu de type RPG avec un système de quêtes." image={rpg} link="https://github.com/Noubouille/my_rpg"></Card>
                <Card name="Trade" description="Trade est un projet Epitech qui consistait à trader des cryptomonnaies de manière automatisée à l'aide d'un algorithme sur une plateforme en ligne." image={trade} link="https://github.com/Noubouille/Trade"></Card>
            </div>
        </div>
    )
}

export default Projects
