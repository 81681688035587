import React from 'react'
import logo from '../assets/default-monochrome-black.svg'
import github from '../assets/bxl-github.svg'
import linkedin from '../assets/bxl-linkedin-square.svg'

function Footer() {
    return (
        <div class="w-full bg-blue-300 flex flex-col lg:flex-row px-12 py-8 items-center text-center lg:text-left">
            <div class="pb-4 lg:pb-0">
                <img src={logo} class="mx-auto" width="150" height="150"></img>
            </div>
            <div class="flex lg:ml-auto">
                <a href="https://github.com/noubouille" target="_blank" rel="noreferrer"><img class="mr-4 w-8" src={github}></img></a>
                <a href="https://www.linkedin.com/in/anthony-l-512ba9198/" target="_blank" rel="noreferrer"><img class="w-8" src={linkedin}></img></a>
            </div>
        </div>
    )
}

export default Footer
