import Header from './components/Header';
import Headline from './components/Headline';
import About from './components/About';
import Experiences from './components/Experiences/Experiences';
import Projects from './components/Projects/Projects';
import Contact from './components/ContactWithModel';
import Footer from './components/Footer';
import './index.css';

function App() {
  return (
    <div>
      <div class="bg-blue-300 pt-8 pb-8 md:pb-0 md:pt-0 z-0">
      <Header />
      <Headline />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#93C5FD" fill-opacity="1" d="M0,256L48,240C96,224,192,192,288,165.3C384,139,480,117,576,128C672,139,768,181,864,213.3C960,245,1056,267,1152,234.7C1248,203,1344,117,1392,74.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      <About />
      <Experiences />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
