import React from 'react'

function Card({name, duration, image, description, role, link, width, height, blankState}) {
    return (
        <div class="transform hover:-translate-y-1 duration-200 hover:scale-105">
            <a href={link} target={blankState} rel="noopener noreferrer">
            <div class="mb-4">
            <img src={image} width={width} height={height} class="mx-auto rounded-lg border-2 border-blue-200 mb-4 lg:max-h-48 lg:max-w-none lg:h-48 lg:block" alt="expérience"/>
            </div>
            <div class="flex flex-row text-center justify-center">
                <h3 class="text-lg font-semibold mr-1">{name}</h3>
                <p class="text-sm my-auto">({ duration }) </p>
            </div>
            <p class="mx-2">{description}</p>
            <p>{role}</p>
            </a>
        </div>
    )
}

export default Card
