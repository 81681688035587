import React, { Fragment } from 'react'
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import cv from '../assets/CV_Anthony-Luong.pdf'

function Contact() {
    let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
      <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-blue-300 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Me contacter 🤙 
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-700">
                    <p class="mb-2">Merci de l'intérêt que vous avez porté à mon profil.</p>Vous pouvez me contacter en adressant un mail à <br/><span class="italic font-semibold">me</span> at <span class="italic font-semibold">anthony.lu</span>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Merci !
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

        <div id="contact" class="w-full bg-blue-200 flex flex-col lg:flex-row px-12 pb-16 py-8 lg:pb-8 text-center lg:text-left">
            <div class="mb-8 lg:mb-0">
            <h2 class="text-2xl font-bold">Intéressé par mon profil ?</h2>
            <p class="text-lg">Restons en contact.</p>
            </div>
            <div class="grid grid-col-1 lg:block lg:ml-auto my-auto items-center">
                <button onClick={() => setIsOpen(true)} class="rounded-full text-gray-100 bg-yellow-500 hover:bg-yellow-600 font-bold p-4 lg:mr-4 mb-4 lg:mb-0">Contactez-moi</button>
                <a href={cv} class="rounded-full p-4 bg-blue-500 hover:bg-blue-600 text-gray-100 font-bold">Mon CV</a>
            </div>
        </div>
        </div>
    )
}

export default Contact
